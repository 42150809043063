<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Toplists <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'toplists.new' }" :disabled="$cannot('toplist.manage')">
          New Toplist
        </b-button>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(title)="row">
          <b-link :to="{ name: 'toplists.edit', params: { id: row.item.slug } }">
            {{ row.item.title }}
          </b-link>
        </template>

        <template v-slot:cell(link_text)="row">
          <b-link :href="row.item.link_url" target="_blank">
            <i class="fa fa-external-link-alt" /> {{ row.item.link_text }}
          </b-link>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ToplistsPage',
  inject: ['$cannot'],
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', sortable: false, label: 'ID' },
        { key: 'title', sortable: false },
        { key: 'slug', sortable: false },
        { key: 'description', sortable: false },
        { key: 'link_text', sortable: false, label: 'Link' },
      ]
    },
  },
  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const response = await this.$store.dispatch('toplists/listing', page)

      this.$nextTick(() => {
        this.listing = response.data()
        this.meta = response.meta
      })
    },
    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'toplists' } : { name: 'toplists', query: { page } })

      this.loadContent()
    },
    paginationLinkGenerator(page) {
      return page === 1 ? { name: 'toplists' } : { name: 'toplists', query: { page } }
    },
  },
}
</script>
